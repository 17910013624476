import Blades from '@/components/BladeResolver'
import { Meta } from '@/globals/Meta'
import { Layout } from '@/hoc/Layout'
import { graphql } from 'gatsby'
import React from 'react'

const GameTemplate = ({ data }) => {
	const game = data?.game
	const blades = game?.blades
	const seo = game?.metadata
	return (
		<Layout locale={`en`}>
			<Meta {...{ seo }} />
			<Blades {...{ blades }} />
		</Layout>
	)
}

export default GameTemplate

export const query = graphql`
	query getGamePage($id: String) {
		game: datoCmsGame(id: { eq: $id }) {
			__typename
			id
			appIcon {
				gatsbyImageData(placeholder: BLURRED)
				alt
			}
			description
			gameName
			character {
				gatsbyImageData(placeholder: BLURRED)
				alt
			}
			logo {
				gatsbyImageData(placeholder: BLURRED)
				alt
			}
			keyArt {
				gatsbyImageData(placeholder: BLURRED)
				alt
			}
			disclaimer
			slug
			metadata {
				description
				title
				twitterCard
				image {
					url
				}
			}
			blades {
				... on DatoCmsGamePageWrapper {
					...GamePageWrapper
				}
				... on DatoCmsHero {
					...Hero
				}
				... on DatoCmsDownloadGameHero {
					...DownloadGameHero
				}
			}
		}
	}
`
